import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaBitcoin, FaEthereum, FaDollarSign } from 'react-icons/fa';
import { useAuth } from "../AuthContext";
import Pagination from './Pagination';

const getCurrencyIcon = (currency) => {
  switch (currency.toLowerCase()) {
    case 'bitcoin':
    case 'btc':
      return <FaBitcoin className="text-yellow-500" />;
    case 'ethereum':
    case 'eth':
      return <FaEthereum className="text-blue-500" />;
    case 'usd':
    case 'dollar':
      return <FaDollarSign className="text-green-500" />;
    default:
      return <FaDollarSign className="text-gray-500" />;
  }
};

const BetTable = ({ bets }) => (
  <div className="hidden md:block bg-gray-800 text-white rounded-lg shadow-lg overflow-hidden">
    <table className="min-w-full table-auto">
      <thead className="bg-gradient-to-r from-purple-700 to-blue-700">
        <tr>
          {['Coin', 'Player', 'Time', 'Predict'].map((heading) => (
            <th
              key={heading}
              className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer hover:text-yellow-400 transition-colors"
            >
              {heading}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-gray-900 divide-y divide-gray-700">
        {bets.length > 0 ? (
          bets.map((bet, index) => (
            <tr
              key={index}
              className={`hover:bg-gray-700 transition-colors`}
            >
              <td className="px-6 py-4 whitespace-nowrap flex items-center space-x-2">
                {getCurrencyIcon(bet.currency)}
                <span>{bet.currency}</span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">{bet.username}</td>
              <td className="px-6 py-4 whitespace-nowrap">{new Date(bet.order_at).toLocaleString()}</td>
              <td className="px-6 py-4 whitespace-nowrap">${bet.amount}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="text-center py-4 text-gray-400">
              No trades available
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

BetTable.propTypes = {
  bets: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      order_at: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    })
  ).isRequired,
};

const BetCards = ({ bets }) => (
  <div className="md:hidden space-y-4">
    {bets.length > 0 ? (
      bets.map((bet, index) => (
        <div
          key={index}
          className={`bg-gradient-to-r from-gray-800 to-gray-900 p-4 rounded-lg shadow-lg`}
        >
          <div className="flex items-center justify-between">
            <span className="font-bold text-gray-400">Coin:</span>
            <span className="flex items-center space-x-2">
              {getCurrencyIcon(bet.currency)}
              <span>{bet.currency}</span>
            </span>
          </div>
          <div className="flex justify-between mt-2">
            <span className="font-bold text-gray-400">Player:</span>
            <span>{bet.username}</span>
          </div>
          <div className="flex justify-between mt-2">
            <span className="font-bold text-gray-400">Time:</span>
            <span>{new Date(bet.order_at).toLocaleString()}</span>
          </div>
          <div className="flex justify-between mt-2">
            <span className="font-bold text-gray-400">Trade:</span>
            <span>${bet.amount}</span>
          </div>
        </div>
      ))
    ) : (
      <p className="text-center text-gray-400">No trades available</p>
    )}
  </div>
);

BetCards.propTypes = BetTable.propTypes;

const Activity = () => {
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useAuth();

  useEffect(() => {
    const fetchBets = async () => {
			try {
				const response = await fetch(
					'https://swaggy-api.debugged.com.my/api/market/live-order',
					{
						method: 'POST',
						credentials: 'include',
						headers: {
							'Content-Type': 'application/json' // Set the content type to JSON
						},
						body: JSON.stringify({
							user_id: user?.id,
							page: currentPage
						})
					}
				);
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const data = await response.json();
				
				setBets(data.data);
				setCurrentPage(data.current_page);
				setTotalPages(data.last_page);
			} catch (err) {
				console.error('Error fetching bets:', err);
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchBets();
  }, [currentPage, setCurrentPage]);

	const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="max-w-3xl mx-auto p-4 text-white mt-20">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-blue-500">
          Recent Activity
        </h2>
      </div>

      {loading && <p className="text-center animate-pulse">Loading...</p>}

      {error && (
        <p className="text-center text-red-500">
          Error fetching trades: {error.message}
        </p>
      )}

      {!loading && !error && (
        <>
          <BetTable bets={bets} />
          <BetCards bets={bets} />
					<Pagination
						totalPages={totalPages}
						currentPage={currentPage}
						onPageChange={handlePageChange}
					/>
        </>
      )}
    </div>
  );
};

export default Activity;
