import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css"; // Import Swiper core styles
import { useNavigate } from "react-router-dom";

const colorThemes = [
  {
    bgColor: "bg-gradient-to-r from-yellow-400 via-yellow-300 to-pink-400",
    buttonColor: "bg-amber-500",
  },
  {
    bgColor: "bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-300",
    buttonColor: "bg-indigo-600",
  },
  {
    bgColor: "bg-gradient-to-r from-cyan-500 to-blue-500",
    buttonColor: "bg-cyan-600",
  },
  {
    bgColor:
      "bg-gradient-to-r from-indigo-600 via-sky-500 via-30% to-emerald-500 to-90%",
    buttonColor: "bg-sky-600",
  },
  {
    bgColor: "bg-gradient-to-r from-yellow-500 via-orange-500 to-red-500",
    buttonColor: "bg-emerald-600",
  },
];

const Events = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [rooms, setRooms] = useState([]);
  const swiperRef = useRef(null); // Reference to Swiper instance
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await fetch(
          "https://swaggy-api.debugged.com.my/api/list"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setRooms(data);
      } catch (error) {
        console.error("Error fetching rooms:", error);
      }
    };

    fetchRooms();
  }, []);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  const handleBulletClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };

  return (
    <div className="mt-20">
      {/* Grid layout for larger screens */}
      <div className="hidden md:grid grid-cols-4 gap-1 justify-items-center">
        {rooms.map((room, index) => {
          const theme = colorThemes[index % colorThemes.length];
          return (
            <EventCard
              key={room.id}
              room={room}
              bgColor={theme.bgColor}
              buttonColor={theme.buttonColor}
              isActive={false}
            />
          );
        })}
      </div>

      {/* Slider layout for mobile screens */}
      <div className="block md:hidden">
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper; // Assign Swiper instance to ref
          }}
          onSlideChange={handleSlideChange}
          slidesPerView={1.25}
          spaceBetween={10}
          centeredSlides={true}
          className="mySwiper"
        >
          {rooms.map((room, index) => {
            const theme = colorThemes[index % colorThemes.length];
            return (
              <SwiperSlide key={room.id}>
                <EventCard
                  room={room}
                  bgColor={theme.bgColor}
                  buttonColor={theme.buttonColor}
                  isActive={index === activeIndex}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        {/* Custom Pagination Dots */}
        <div className="flex justify-center items-center mt-1">
          {rooms.map((_, index) => (
            <span
              key={index}
              onClick={() => handleBulletClick(index)}
              className={`w-2 items-center h-2 mx-1 rounded-full cursor-pointer transition-colors duration-300 ${
                index === activeIndex
                  ? "bg-white"
                  : "bg-white/60 hover:bg-white w-1 h-1"
              }`}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

const EventCard = ({ room, bgColor, buttonColor, isActive }) => {
  const navigate = useNavigate();

  const handleBetNowClick = () => {
    navigate(`/event/${room.name.toLowerCase()}/${room.id}`);
  };

  const handleButtonClick = (e) => {
    e.stopPropagation();
    handleBetNowClick();
  };

  return (
    <div
      className={`group m-2 mt-11 relative rounded-3xl px-4 py-1 grid grid-cols-2 gap-4 items-center ${bgColor} overflow-visible transform transition-transform duration-300 ${
        isActive ? "scale-105 shadow-lg" : ""
      } md:hover:scale-105 md:hover:shadow-lg cursor-pointer`}
      onClick={handleBetNowClick}
      style={{
        backgroundImage: `url(${room.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Overlay to darken the background */}
      <div
        className={`absolute inset-0 bg-black rounded-3xl transition-opacity duration-300 ${
          isActive ? "opacity-0" : "opacity-30 group-hover:opacity-10"
        }`}
      ></div>

      {/* Content above the overlay */}
      <div className="col-span-1 relative z-10">
        <h3 className="text-base font-semibold text-white text-center drop-shadow-lg">
          Predict the price of {room.name}
        </h3>
        <div className="flex justify-center mt-4">
          <button
            className={`${buttonColor} px-3 py-1 text-sm text-white font-semibold rounded-full shadow-md hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-opacity-75`}
            onClick={handleButtonClick}
          >
            Predict Now
          </button>
        </div>
      </div>

      {room.currencyImage && (
        <div className="col-span-1 relative h-40 z-10">
          <div className="absolute right-[-30px] top-[-30px] w-[150px] h-[150px] transition-transform duration-300 transform md:group-hover:translate-y-[-10px]">
            <img
              src={room.currencyImage}
              alt={`${room.name} icon`}
              className={`w-full h-full object-cover transition-transform duration-300 ${
                isActive ? "translate-y-[-10px]" : "translate-y-0"
              }`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Events;
