import React, { useState } from 'react';
import { FiMail, FiLock } from 'react-icons/fi';

const Settings = () => {
  const [email, setEmail] = useState('');
  const [currentPasswordForEmail, setCurrentPasswordForEmail] = useState('');
  const [currentPasswordForPassword, setCurrentPasswordForPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [emailSuccess, setEmailSuccess] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmailSuccess('Your email has been updated successfully.');
    setErrorMessage('');
    console.log('Email updated to:', email);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage('New passwords do not match!');
      setPasswordSuccess('');
      return;
    }
    setPasswordSuccess('Your password has been updated successfully.');
    setErrorMessage('');
    console.log('Password updated');
  };

  return (
    <div className="max-w-3xl mx-auto mt-10 p-8 rounded-xl shadow-lg text-gray-200">
      <h2 className="text-4xl font-extrabold mb-12 text-center text-gray-100">Account Settings</h2>

      {/* Change Email Section */}
      <form onSubmit={handleEmailChange} className="mb-16">
        <h3 className="text-2xl font-semibold mb-8 text-gray-100">Change Email</h3>
        {emailSuccess && (
          <div className="mb-6 text-green-400 font-medium">{emailSuccess}</div>
        )}
        <div className="mb-6">
          <label className="block text-gray-400 mb-2">New Email Address</label>
          <div className="flex items-center border-b border-gray-600 py-2">
            <FiMail className="text-gray-500 mr-3" />
            <input
              type="email"
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none"
              placeholder="Enter your new email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-gray-400 mb-2">Current Password</label>
          <div className="flex items-center border-b border-gray-600 py-2">
            <FiLock className="text-gray-500 mr-3" />
            <input
              type="password"
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none"
              placeholder="Enter your current password"
              value={currentPasswordForEmail}
              onChange={(e) => setCurrentPasswordForEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full py-4 mt-6 bg-blue-700 text-white rounded-lg hover:bg-blue-800 transition duration-300 font-semibold"
        >
          Update Email
        </button>
      </form>

      <hr className="border-t border-gray-700 mb-16" />

      {/* Change Password Section */}
      <form onSubmit={handlePasswordChange}>
        <h3 className="text-2xl font-semibold mb-8 text-gray-100">Change Password</h3>
        {passwordSuccess && (
          <div className="mb-6 text-green-400 font-medium">{passwordSuccess}</div>
        )}
        {errorMessage && (
          <div className="mb-6 text-red-500 font-medium">{errorMessage}</div>
        )}
        <div className="mb-6">
          <label className="block text-gray-400 mb-2">Current Password</label>
          <div className="flex items-center border-b border-gray-600 py-2">
            <FiLock className="text-gray-500 mr-3" />
            <input
              type="password"
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none"
              placeholder="Enter your current password"
              value={currentPasswordForPassword}
              onChange={(e) => setCurrentPasswordForPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-gray-400 mb-2">New Password</label>
          <div className="flex items-center border-b border-gray-600 py-2">
            <FiLock className="text-gray-500 mr-3" />
            <input
              type="password"
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none"
              placeholder="Enter your new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-10">
          <label className="block text-gray-400 mb-2">Confirm New Password</label>
          <div className="flex items-center border-b border-gray-600 py-2">
            <FiLock className="text-gray-500 mr-3" />
            <input
              type="password"
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full py-4 bg-green-700 text-white rounded-lg hover:bg-green-800 transition duration-300 font-semibold"
        >
          Update Password
        </button>
      </form>
    </div>
  );
};

export default Settings;
