import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

const PaymentStatus = () => {
    // Get the dynamic parameter from the route
    const { gateway } = useParams();
    const [searchParams] = useSearchParams();
    const status = searchParams.get('status'); // 'success'

    // State variables
    const [message, setMessage] = useState("Redirecting...")

    const navigate = useNavigate();

    useEffect(() => {
        let newStatus = "";
        if( gateway && status ) {
            newStatus = status;
        } else {
            newStatus = "pending";
        }

        navigate(`/dashboard?status=${newStatus}&gateway=${gateway}`);
        
    }, [status, gateway, navigate]); // Run when these dependencies change

    return (
        <div className="mt-[6rem] text-white text-center text-md">
            <p>{message}</p>
        </div>
    );
};

export default PaymentStatus;
