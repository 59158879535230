import React from 'react';

const AssetRecords = ({ records }) => {
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Asset Records</h2>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Coin</th>
            <th className="py-2 px-4 border-b">Bet Price</th>
            <th className="py-2 px-4 border-b">Amount</th>
            <th className="py-2 px-4 border-b">Time</th>
          </tr>
        </thead>
        <tbody>
          {records && records.length > 0 ? (
            records.map((record, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{record.coin}</td>
                <td className="py-2 px-4 border-b">{record.price}</td>
                <td className="py-2 px-4 border-b">{record.amount}</td>
                <td className="py-2 px-4 border-b">{new Date(record.time).toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="py-2 px-4 border-b text-center">
                No records available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AssetRecords;
