import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(null);
    const [hasBonus, setHasBonus] = useState(false);
    const [totalBonus, setTotalBonus] = useState(0);
    const [loading, setLoading] = useState(true);
    const [balanceLoading, setBalanceLoading] = useState(true);
    const [isUserOnTrialMode, setIsUserOnTrialMode] = useState(true);
    const [trialAttempts, setTrialAttempts] = useState(0);

    const fetchBalance = async (userId) => {
        setBalanceLoading(true);
        try {
            const token = localStorage.getItem('sanctum_token');

            const response = await fetch(`https://swaggy-api.debugged.com.my/api/wallet`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include', // Include credentials for cookie-based auth
            });

            if (!response.ok) {
                throw new Error(`Error fetching balance: ${response.statusText}`);
            }
            const data = await response.json();
            setBalance(data.balance);
            setHasBonus(data.has_bonus);
            setTotalBonus(data.total_bonus)
        } catch (error) {
            console.error('Error fetching balance:', error);
        } finally {
            setBalanceLoading(false);
        }
    };

    const verifyUserTrial = async () => {
        try {
            const token = localStorage.getItem('sanctum_token');

            const response = await fetch(`https://swaggy-api.debugged.com.my/api/verify-user-trial`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                credentials: 'include', // Include credentials for cookie-based auth
            });

            if (!response.ok) {
                throw new Error(`Error verifying user trial: ${response.statusText}`);
            }

            const data = await response.json();

            setTrialAttempts(data.trial_attempts);
            setIsUserOnTrialMode(data.on_trial);
        } catch (error) {
            console.error('Error verifying user trial:', error);
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const storedUser = sessionStorage.getItem('user');
                    if (storedUser) {
                        login(JSON.parse(storedUser));
                    }
            } catch (error) {
                console.error('Error fetching user:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const login = async (userData) => {
        setUser(userData);
        await fetchBalance(userData.id); // Fetch balance on login
        await verifyUserTrial();
    };

    const logout = async () => {
        try {
            const response = await fetch("https://swaggy-api.debugged.com.my/api/logout", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('sanctum_token')}`, // Include token if needed
                },
                credentials: 'include'
            });
    
            if (!response.ok) {
                throw new Error('Logout failed');
            }
    
            // Clear user data from storage
            localStorage.removeItem('sanctum_token');
            sessionStorage.removeItem('user');
            // Update state to reflect logout
            setUser(null);
            setBalance(null);
            setBalanceLoading(true);
        } catch (error) {
            console.error('Logout Error:', error);
            throw error; // Rethrow or handle the error as needed
        }// Reset balance loading state
    };

    return (
        <AuthContext.Provider value={{ user, balance, isAuthenticated: !!user, login, logout, loading, balanceLoading, fetchBalance, isUserOnTrialMode, trialAttempts, setTrialAttempts, verifyUserTrial, hasBonus, totalBonus }}>
            {children}
        </AuthContext.Provider>
    );
};
