import React, { useEffect, useState } from "react";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const token = localStorage.getItem("token");

    fetch("https://swaggy-api.debugged.com.my/api/admin/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const sortUsers = () => {
    const sortedUsers = [...users].sort((a, b) => {
      const dateA = new Date(a.date_registered);
      const dateB = new Date(b.date_registered);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    setUsers(sortedUsers);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container mx-auto my-8 p-4 bg-white shadow rounded mt-20">
      <h1 className="text-2xl font-semibold mb-4">Users List</h1>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200">Username</th>
            <th className="py-2 px-4 border-b border-gray-200">Email</th>
            <th
              className="py-2 px-4 border-b border-gray-200 cursor-pointer"
              onClick={sortUsers}
            >
              Date Registered
              <span className="ml-2">{sortOrder === "asc" ? "▲" : "▼"}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="py-2 px-4 border-b border-gray-200">
                {user.username}
              </td>
              <td className="py-2 px-4 border-b border-gray-200">
                {user.email}
              </td>
              <td className="py-2 px-4 border-b border-gray-200">
                {new Date(user.date_registered).toLocaleDateString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersList;
