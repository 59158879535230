import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-date-fns";
import { useMediaQuery } from "react-responsive";

const timeFrames = ["1D", "1W", "1M", "3M", "1Y"];

const ChartComponent = ({ realTimePrice }) => {
  const { coin } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [chartData, setChartData] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(realTimePrice);
  const [priceChange, setPriceChange] = useState(null);
  const [percentageChange, setPercentageChange] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("1D");
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef(null);
  // Interval reference for periodic updates
  // const intervalRef = useRef(null);

  // Helper functions defined before they are used
  const getTimeRange = useCallback((timeFrame) => {
    const now = Date.now();
    switch (timeFrame) {
      case "1D":
        return { min: now - 24 * 60 * 60 * 1000, max: now };
      case "1W":
        return { min: now - 7 * 24 * 60 * 60 * 1000, max: now };
      case "1M":
        return { min: now - 30 * 24 * 60 * 60 * 1000, max: now };
      case "3M":
        return { min: now - 90 * 24 * 60 * 60 * 1000, max: now };
      case "1Y":
        return { min: now - 365 * 24 * 60 * 60 * 1000, max: now };
      default:
        return { min: now - 24 * 60 * 60 * 1000, max: now };
    }
  }, []);

  const getIntervalForTimeFrame = useCallback((timeFrame) => {
    switch (timeFrame) {
      case "1D":
        return "5m";
      case "1W":
        return "30m";
      case "1M":
        return "1h";
      case "3M":
        return "6h";
      case "1Y":
        return "1d";
      default:
        return "5m";
    }
  }, []);

  const getLimitForTimeFrame = useCallback((timeFrame) => {
    switch (timeFrame) {
      case "1D":
        return 288; // 5-minute intervals over 24 hours
      case "1W":
        return 336; // 30-minute intervals over 7 days
      case "1M":
        return 720; // 1-hour intervals over 30 days
      case "3M":
        return 360; // 6-hour intervals over 90 days
      case "1Y":
        return 365; // 1-day intervals over 365 days
      default:
        return 288;
    }
  }, []);

  const getTimeUnitForTimeFrame = useCallback((timeFrame) => {
    switch (timeFrame) {
      case "1D":
        return "hour";
      case "1W":
        return "day";
      case "1M":
      case "3M":
        return "week";
      case "1Y":
        return "month";
      default:
        return "hour";
    }
  }, []);

  // const getUpdateInterval = useCallback((timeFrame) => {
  //   switch (timeFrame) {
  //     case "1D":
  //       return 5 * 60 * 1000; // Update every 5 minutes
  //     case "1W":
  //       return 30 * 60 * 1000; // Update every 30 minutes
  //     case "1M":
  //       return 60 * 60 * 1000; // Update every 1 hour
  //     case "3M":
  //       return 6 * 60 * 60 * 1000; // Update every 6 hours
  //     case "1Y":
  //       return 24 * 60 * 60 * 1000; // Update every 24 hours
  //     default:
  //       return 5 * 60 * 1000;
  //   }
  // }, []);

  const getChartOptions = useCallback(
    (timeRange) => {
      return {
        devicePixelRatio:
          typeof window !== "undefined" ? window.devicePixelRatio : 1,
        maintainAspectRatio: false,
        responsive: true,
        animation: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            intersect: false,
            mode: "index",
            backgroundColor: "#fff",
            titleColor: "#000",
            bodyColor: "#000",
            padding: 10,
            cornerRadius: 4,
            displayColors: false,
            callbacks: {
              label: (context) => `$${context.parsed.y.toFixed(2)}`,
              title: () => null, // Do not show the date
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: isMobile ? 0 : 0.4,
          },
        },
        scales: {
          x: {
            type: "time",
            display: true, // Show gridlines and labels
            time: {
              unit: getTimeUnitForTimeFrame(selectedTimeFrame),
            },
            min: timeRange.min,
            max: timeRange.max,
            grid: {
              display: true,
              color: "#444",
            },
            ticks: {
              color: "#ccc",
            },
          },
          y: {
            display: true, // Show gridlines and labels
            position: "right", // Move the y-axis to the right
            grid: {
              display: true,
              color: "#444",
            },
            ticks: {
              color: "#ccc",
            },
          },
        },
      };
    },
    [isMobile, getTimeUnitForTimeFrame, selectedTimeFrame]
  );

  const fetchChartData = useCallback(
    async (timeFrame) => {
      try {
        const symbol = `${coin.toUpperCase()}USDT`;
        const interval = getIntervalForTimeFrame(timeFrame);
        const limit = getLimitForTimeFrame(timeFrame);
        const response = await fetch(
          `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${interval}&limit=${limit}`
        );
        const data = await response.json();

        if (data.code) {
          throw new Error(`Error fetching data: ${data.msg}`);
        }

        const labels = data.map((item) => new Date(item[0]));
        const prices = data.map((item) => parseFloat(item[4]));

        if (labels.length === 0 || prices.length === 0) {
          throw new Error("No data available for the selected time frame.");
        }

        const firstPrice = prices[0];
        const lastPrice = prices[prices.length - 1];

        setCurrentPrice(lastPrice);
        const priceChangeValue = lastPrice - firstPrice;
        setPriceChange(priceChangeValue.toFixed(2));

        const percentage = ((lastPrice - firstPrice) / firstPrice) * 100;
        setPercentageChange(percentage.toFixed(2));

        const isPriceUp = priceChangeValue >= 0;
        const lineColor = isPriceUp ? "#21ce99" : "#f45531";

        const processedData = {
          labels: labels,
          datasets: [
            {
              data: prices,
              borderColor: lineColor,
              backgroundColor: lineColor,
              fill: false,
              tension: isMobile ? 0 : 0.4,
              pointRadius: 0,
              borderWidth: 2,
            },
          ],
        };
        setChartData(processedData);

        const timeRange = getTimeRange(selectedTimeFrame);
        setChartOptions(getChartOptions(timeRange));
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    },
    [
      coin,
      selectedTimeFrame,
      isMobile,
      getIntervalForTimeFrame,
      getLimitForTimeFrame,
      getTimeRange,
      getChartOptions,
    ]
  );

  const updateChartData = useCallback(
    (newPrice) => {
      if (chartData && chartData.labels && chartData.datasets) {
        const newLabels = [...chartData.labels, new Date()];
        const newPrices = [...chartData.datasets[0].data, newPrice];

        const firstPrice = newPrices[0];
        const lastPrice = newPrice;

        setCurrentPrice(lastPrice);
        const priceChangeValue = lastPrice - firstPrice;
        setPriceChange(priceChangeValue.toFixed(2));

        const percentage = ((lastPrice - firstPrice) / firstPrice) * 100;
        setPercentageChange(percentage.toFixed(2));

        const isPriceUp = priceChangeValue >= 0;
        const lineColor = isPriceUp ? "#21ce99" : "#f45531";

        setChartData({
          labels: newLabels,
          datasets: [
            {
              ...chartData.datasets[0],
              data: newPrices,
              borderColor: lineColor,
              backgroundColor: lineColor,
            },
          ],
        });
      }
    },
    [chartData, selectedTimeFrame, getLimitForTimeFrame]
  );

  useEffect(() => {
    fetchChartData(selectedTimeFrame);
  }, [selectedTimeFrame, fetchChartData]);

  useEffect(() => {
    if (realTimePrice) {
      updateChartData(realTimePrice);
    }
  }, [realTimePrice]);

  const handleTimeFrameChange = (newTimeFrame) => {
    setSelectedTimeFrame(newTimeFrame);
  };

  return (
    <div className="chart-container w-full flex flex-col">
      {/* Chart Header */}
      <div className="text-center mb-4 text-white">
        <h1 className="text-2xl sm:text-3xl font-bold">
          What will be the price of {coin.toUpperCase()}?
        </h1>
        {currentPrice && (
          <p className="text-xl sm:text-2xl">
            ${currentPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </p>
        )}

        {/* Display price change and percentage change */}
        {priceChange && percentageChange && (
          <p
            className={`text-lg sm:text-xl ${priceChange >= 0 ? "text-green-500" : "text-red-500"
              }`}
          >
            {priceChange >= 0 ? `+${priceChange}` : `${priceChange}`} (
            {percentageChange}%)
          </p>
        )}
      </div>

      {/* Chart Container */}
      <div
        className="w-full flex-grow flex items-center justify-center"
        style={{ height: "400px" }}
      >
        {chartData ? (
          <Line ref={chartRef} data={chartData} options={chartOptions} />
        ) : (
          // Loading Spinner
          <div className="flex items-center justify-center">
            <div
              className="w-10 h-10 border-4 border-t-transparent border-white rounded-full animate-spin"
              role="status"
              aria-label="Loading chart"
            ></div>
          </div>
        )}
      </div>

      {/* Timeframes */}
      <div className="flex justify-center flex-wrap space-x-2 sm:space-x-4 mt-4">
        {timeFrames.map((timeFrame) => (
          <button
            key={timeFrame}
            onClick={() => handleTimeFrameChange(timeFrame)}
            className={`text-sm sm:text-base px-2 py-1 sm:px-3 sm:py-2 rounded-md ${selectedTimeFrame === timeFrame
                ? "bg-gray-700 text-white"
                : "text-gray-400"
              }`}
          >
            {timeFrame}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ChartComponent;
