import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Icons for dropdown

const Privacy = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="privacy-page max-w-4xl mx-auto p-6 mt-20">
      <h1 className="text-4xl font-bold text-center mb-8 text-white">Privacy Policy</h1>
      <p className="text-gray-300 text-center mb-6">Last Updated: September 13, 2024</p>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('introduction')}
        >
          Introduction
          {openSection === 'introduction' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'introduction' && (
          <div className="mt-4 text-white">
            <p>
              This website-hosted user interface (this “Interface”) is made available by SWAGGYX CORPORATION SOCIEDAD DE RESPONSABILIDAD LIMITADA, a corporation organized and existing under the laws of the Republic of Costa Rica (the “Company,” “us,” “we,” or “our”)).
            </p>
            <p className="mt-2">
              This Privacy Policy (the “Policy”) governs how we collect, use, maintain, and disclose information collected from our users (each a "user," “you,” or “your”) through our websites, web applications, mobile applications, and associated sites linked thereto (the “Site”).
            </p>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('information')}
        >
          Information Collection and Use
          {openSection === 'information' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'information' && (
          <div className="mt-4 text-white">
            <p>We collect information:</p>
            <ul className="list-disc list-inside ml-4 mt-2">
              <li>Directly from you when you provide it to us;</li>
              <li>Automatically as you navigate through the site;</li>
              <li>From third parties such as business partners or networks where you have connected your account.</li>
            </ul>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('children')}
        >
          Children Under the Age of 18
          {openSection === 'children' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'children' && (
          <div className="mt-4 text-white">
            <p>
              Our Site is not intended for children under 18 years of age. No one under age 18 may provide any personal information to or on the Site.
            </p>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('cookies')}
        >
          Use of Cookies
          {openSection === 'cookies' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'cookies' && (
          <div className="mt-4 text-white">
            <p>
              We may use cookies and other tracking technologies to recognize you as a customer, customize the Services, and for various other purposes.
            </p>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('useOfInfo')}
        >
          Use of Your Information
          {openSection === 'useOfInfo' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'useOfInfo' && (
          <div className="mt-4 text-white">
            <p>We may use the information we collect about you to:</p>
            <ul className="list-disc list-inside ml-4 mt-2">
              <li>Administer sweepstakes, promotions, and contests;</li>
              <li>Contact you regarding your account;</li>
              <li>Provide targeted advertising, coupons, newsletters, and other promotional information;</li>
              <li>Prevent fraudulent activities and enforce our Terms of Service.</li>
            </ul>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('rights')}
        >
          Data Protection Rights
          {openSection === 'rights' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'rights' && (
          <div className="mt-4 text-white">
            <p>
              Depending on applicable law where you reside, you may have certain rights regarding your personal information, including the right to access, correct, or delete your information.
            </p>
            <p>
              If you have any questions about this Policy, please contact us by email at{' '}
              <a href="mailto:legal@swaggybet.com" className="text-blue-400 underline">
                legal@swaggybet.com
              </a>.
            </p>
          </div>
        )}
      </section>
    </div>
  );
};

export default Privacy;
