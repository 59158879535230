import React, { useState, useRef } from "react";
import { useAuth } from "../AuthContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { GoogleLogin } from "@react-oauth/google";
import OTPScreen from "./User/OTPScreen";
import PasswordRecovery from "./PasswordRecovery";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import RecaptchaV3 from "./ReCaptcha";

const LoginForm = ({ onClose, mode = 'login', setFormMode, apiHost, apiVersion }) => {
  // State variables
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [captchaError, setCaptchaError] = useState('');
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [otpEmail, setOtpEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [isConnecting, setIsConnecting] = useState(false);
  const [showUsernamePrompt, setShowUsernamePrompt] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
  const captchaToken = useRef('');

  const { login } = useAuth();
  const navigate = useNavigate();
  const isLogin = mode === "login";

  // Username validation function
  const validateUsername = (username) => {
    const minLength = 3;
    const allowedCharsRegex = /^[a-zA-Z0-9_]+$/;
    const hasLetterRegex = /[a-zA-Z]/;

    if (username.length < minLength) {
      return `Username must be at least ${minLength} characters long.`;
    }
    if (!allowedCharsRegex.test(username)) {
      return "Username can only contain letters, numbers, and underscores.";
    }
    if (!hasLetterRegex.test(username)) {
      return "Username cannot consist of numbers only.";
    }
    return "";
  };

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    if (!isLogin) {
      const usernameValidationError = validateUsername(newUsername);
      setUsernameError(usernameValidationError);
    }
  };

  // Password validation function
  const validatePassword = (password) => {
    const minLength = 8;
    let strength = '';

    if (password.length < minLength) {
      strength = 'Password not long enough';
    } else {
      const hasLetters = /[a-zA-Z]/.test(password);
      const hasNumbers = /[0-9]/.test(password);
      const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      let strengthScore = 0;
      if (hasLetters) strengthScore++;
      if (hasNumbers) strengthScore++;
      if (hasSpecialChars) strengthScore++;

      if (strengthScore === 1) {
        strength = 'Weak';
      } else if (strengthScore === 2) {
        strength = 'Average';
      } else if (strengthScore === 3) {
        strength = 'Strong';
      }
    }

    setPasswordStrength(strength);

    return password.length >= minLength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!isLogin) {
      validatePassword(newPassword);
    }
  };

  const handleVerifyCaptcha = (token) => {
    captchaToken.current = token;
    console.log(captchaToken.current);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!captchaToken.current) {
      setCaptchaError("Please complete the captcha.");
      return;
    }

    if (!isLogin && password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const endpoint = isLogin
      ? `${apiHost}${apiVersion}/login`
      : `${apiHost}${apiVersion}/register`;
    const payload = isLogin
      ? { email, password, captchaToken: captchaToken.current }
      : {
        name: username,
        email,
        password,
        password_confirmation: confirmPassword,
        captchaToken: captchaToken.current,
      };

    setLoading(true);
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();

        if (response.ok) {
          setSuccess(data.message);
          setError("");
          if (isLogin) {
            setOtpEmail(email);
            setShowOtpScreen(true);
          } else {
            setFormMode("login");
          }
        } else {
          setError(
            data.message ||
            data.errors?.email?.[0] ||
            data.errors?.password?.[0] ||
            data.errors?.name?.[0] ||
            'Invalid credentials'
          );
          setSuccess("");
        }
      } else {
        const text = await response.text();
        console.error("Non-JSON response:", text);
        setError(`Unexpected response format: ${text}`);
        setSuccess("");
      }

    } catch (error) {
      console.error('Fetch Error:', error);
      setError(error.message || "An error occurred, please try again later.");
      setSuccess("");
    } finally {
      setLoading(false);
    }
  };

  // Google Login Success
  const handleGoogleLoginSuccess = (credentialResponse) => {
    console.log("Google Login Success:", credentialResponse);
  };

  // Google Login Failure
  const handleGoogleLoginFailure = (error) => {
    console.error("Google Login Failed:", error);
    setError("Google login failed. Please try again.");
  };

  // Connect Wallet Function
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        setIsConnecting(true);
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        const currentWalletAddress = accounts[0];
        setWalletAddress(currentWalletAddress);

        await new Promise((resolve) => setTimeout(resolve, 100));

        const response = await handleWalletLogin(currentWalletAddress);
        if (response.needRegistration) {
          setError("");
          setShowUsernamePrompt(true);
        } else if (response.success) {
          setSuccess(response.message);
          setError("");
          sessionStorage.setItem("user", JSON.stringify(response.user));
          localStorage.setItem("sanctum_token", response.token);
          login(response.user);
          onClose();
        } else if (response.error) {
          setError(response.error);
          setSuccess('');
        }
      } catch (error) {
        console.error("Error connecting wallet:", error);
        setError("Failed to connect wallet. Please try again.");
      } finally {
        setIsConnecting(false);
      }
    } else {
      setError(
        'MetaMask is not installed. Please install it from ' +
        '<a href="https://metamask.io/download.html" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">here</a>.'
      );
    }
  };

  const handleWalletLogin = async (currentWalletAddress) => {
    const payload = {
      wallet_address: currentWalletAddress,
      captchaToken: captchaToken.current,
    };

    try {
      const response = await fetch(`${apiHost}${apiVersion}/login-wallet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const data = await response.json();

      if (response.ok) {
        if (data.message === "Redirect to register") {
          return { needRegistration: true };
        } else {
          return { success: true, user: data.user, token: data.token, message: data.message };
        }
      } else {
        return { error: data.message || 'Failed to login with wallet' };
      }
    } catch (error) {
      return { error: error.message || 'An error occurred, please try again later.' };
    }
  };

  const handleWalletRegister = async (username) => {
    const usernameValidationError = validateUsername(username);
    if (usernameValidationError) {
      setError(usernameValidationError);
      return;
    }

    const payload = {
      name: username,
      wallet_address: walletAddress,
    };

    try {
      const response = await fetch(`${apiHost}${apiVersion}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      });

      const data = await response.json();
      if (response.ok) {
        setSuccess(data.message);
        setError("");
        onClose();
      } else {
        console.error(data.message || "Registration failed");
        setSuccess("");
        setError(data.message || "Registration failed");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setError(error.message || "An error occurred, please try again later.");
      setSuccess("");
    }
  };

  const handleUsernameSubmit = async () => {
    if (!newUsername.trim()) {
      setError("Username is required");
      return;
    }
    await handleWalletRegister(newUsername);
    setShowUsernamePrompt(false);
    setNewUsername("");
    setSuccess("Registration successful");
  };

  return (
    <div
      id="login-popup"
      tabIndex="-1"
      className="bg-black/50 fixed inset-0 overflow-y-auto flex items-start justify-center z-50 min-h-screen"
    >

      <div className="relative p-4 sm:p-6 w-full max-w-md bg-white rounded-lg shadow-md mt-8 mb-8">
        <button
          type="button"
          className="absolute top-2 right-2 sm:top-3 sm:right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1 sm:p-1.5"
          onClick={onClose}
        >
          <XMarkIcon className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
          <span className="sr-only">Close popup</span>
        </button>

        {showPasswordRecovery && (
          <PasswordRecovery
            onClose={() => setShowPasswordRecovery(false)}
            apiHost={apiHost}
            apiVersion={apiVersion}
          />
        )}

        {showOtpScreen ? (
          <OTPScreen
            onClose={() => setShowOtpScreen(false)}
            email={otpEmail}
            onSuccess={() => {
              onClose();
              setShowOtpScreen(false);
            }}
          />
        ) : showUsernamePrompt ? (
          <div className="p-6">
            <h2 className="text-xl sm:text-2xl font-semibold text-slate-900 mb-4">
              Enter Username
            </h2>
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <input
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mb-4"
              placeholder="Username"
            />
            <button
              onClick={handleUsernameSubmit}
              className="w-full py-3 rounded-lg bg-black text-white font-medium text-base sm:text-sm focus:ring-2 focus:ring-black"
            >
              Register
            </button>
            <button
              onClick={() => {
                setError("");
                setSuccess("");
                setShowUsernamePrompt(false);
                setFormMode("login");
              }}
              className="w-full py-2 mt-2 text-center text-sm text-gray-600 hover:underline"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="p-6">
            <div className="text-center mb-4">
              <h2 className="text-xl sm:text-2xl font-semibold text-slate-900">
                {isLogin ? "Login to your account" : "Create an account"}
              </h2>
              <p className="text-sm sm:text-base text-slate-600 mt-2">
                {isLogin
                  ? "You must be logged in to perform this action."
                  : "Sign up to get started."}
              </p>
            </div>

            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            {success && (
              <p className="text-green-500 text-center mb-4">{success}</p>
            )}

            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
              useOneTap
            />

            <button
              type="button"
              onClick={connectWallet}
              disabled={isConnecting}
              className="w-full py-3 rounded-lg bg-black text-white font-medium text-base sm:text-sm focus:ring-2 focus:ring-black mt-4"
            >
              {isConnecting ? "Connecting..." : "Connect MetaMask"}
            </button>

            <div className="flex w-full items-center gap-2 py-6 text-sm text-slate-600">
              <div className="h-px w-full bg-slate-200"></div>
              OR
              <div className="h-px w-full bg-slate-200"></div>
            </div>

            <form className="space-y-4" onSubmit={handleSubmit}>
              {!isLogin && (
                <>
                  <label htmlFor="username" className="sr-only">
                    Username
                  </label>
                  <input
                    name="username"
                    type="text"
                    required
                    value={username}
                    onChange={handleUsernameChange}
                    className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                    placeholder="Username"
                  />
                  {usernameError && (
                    <p className="text-red-500 text-sm">{usernameError}</p>
                  )}
                </>
              )}
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                placeholder="Email Address"
              />
              {!isLogin ? (
                <>
                  <div className="flex justify-between items-center mt-4">
                    <label htmlFor="password" className="text-sm font-medium text-gray-700">
                      New password (8 characters min)
                    </label>
                    <button
                      type="button"
                      className="text-blue-600 hover:underline text-sm"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </button>
                  </div>
                  <input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                    placeholder="Password"
                  />
                  <p className="text-sm mt-2 text-black">
                    Password strength:{" "}
                    <span
                      className={
                        passwordStrength === "Strong"
                          ? "text-green-500"
                          : passwordStrength === "Average"
                            ? "text-yellow-500"
                            : passwordStrength === "Weak"
                              ? "text-red-500"
                              : passwordStrength === "Password not long enough"
                                ? "text-red-500"
                                : ""
                      }
                    >
                      {passwordStrength}
                    </span>
                  </p>

                  {/* Conditionally show this message if the password is weak or average */}
                  {(passwordStrength === "Weak" || passwordStrength === "Average") && (
                    <p className="text-sm text-gray-600 mt-1">
                      A strong password can improve the security of your account should your device be stolen or compromised.
                    </p>
                  )}

                  <label htmlFor="confirm-password" className="sr-only">
                    Confirm Password
                  </label>
                  <input
                    name="confirm-password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="block w-full rounded-lg border border-gray-300 px-4 py-2 mt-4 text-base shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                    placeholder="Confirm Password"
                  />
                </>
              ) : (
                <>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black"
                    placeholder="Password"
                  />
                </>
              )}
              {passwordError && (
                <p className="text-red-500 text-sm">{passwordError}</p>
              )}
              <RecaptchaV3 action="submit" onVerify={handleVerifyCaptcha} />
              <button
                type="submit"
                className={`w-full py-3 rounded-lg bg-black text-white font-medium text-base sm:text-sm focus:ring-2 focus:ring-black ${loading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                disabled={loading}
              >
                {loading ? "Loading..." : isLogin ? "Continue" : "Sign Up"}
              </button>
              {captchaError && (
                <p className="text-red-500 text-sm text-center">
                  {captchaError}
                </p>
              )}
            </form>

            <div className="mt-6 text-center text-sm sm:text-base text-slate-600 space-y-2">
              {isLogin ? (
                <>
                  <div>
                    Don't have an account?{" "}
                    <button
                      onClick={() => setFormMode("register")}
                      className="font-medium text-blue-600 hover:underline"
                    >
                      Sign up
                    </button>
                  </div>
                  <div>
                    Forgot your password?{" "}
                    <button
                      onClick={() => setShowPasswordRecovery(true)}
                      className="font-medium text-blue-600 hover:underline"
                    >
                      Reset it
                    </button>
                  </div>
                </>
              ) : (
                <>
                  Already have an account?{" "}
                  <button
                    onClick={() => setFormMode("login")}
                    className="font-medium text-blue-600 hover:underline"
                  >
                    Login
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginForm;