import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Icons for dropdown

const Tac = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="tac-page max-w-4xl mx-auto p-6 mt-20">
      <h1 className="text-4xl font-bold text-center mb-8 text-white">Terms and Conditions</h1>
      <p className="text-gray-300 text-center mb-6">Last Updated: September 13, 2024</p>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('introduction')}
        >
          Introduction
          {openSection === 'introduction' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'introduction' && (
          <div className="mt-4 text-white">
            <p>
              This website-hosted user interface (this “Interface”) is made available by SWAGGYX CORPORATION SOCIEDAD DE RESPONSABILIDAD LIMITADA, a corporation organized and existing under the laws of the Republic of Costa Rica (the “Company”).
            </p>
            <p className="mt-2">
              These terms of use govern your access to the Company’s websites, including the Interface, web applications, mobile applications, and associated sites (collectively the “Site”).
            </p>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('modifications')}
        >
          Modifications to These Terms
          {openSection === 'modifications' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'modifications' && (
          <div className="mt-4 text-white">
            <p>
              We reserve the right to modify these Terms at our sole discretion. If we make changes, we will provide notice, and your continued use of the Site after notice constitutes your acceptance of the changes.
            </p>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('useOfServices')}
        >
          Use of Services
          {openSection === 'useOfServices' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'useOfServices' && (
          <div className="mt-4 text-white">
            <p>
              By accessing the Site or using the Services, you represent and warrant that you have the legal capacity to enter into these Terms, and that your use of the Services complies with all applicable laws.
            </p>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('fees')}
        >
          Fees and Price Estimates
          {openSection === 'fees' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'fees' && (
          <div className="mt-4 text-white">
            <p>
              You are required to pay all fees necessary for interacting with the Ethereum or other blockchains, including transaction costs (e.g., gas fees), as well as all other fees reflected on the Site at the time of your use.
            </p>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('prohibitedActivities')}
        >
          Prohibited Activities
          {openSection === 'prohibitedActivities' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'prohibitedActivities' && (
          <div className="mt-4 text-white">
            <p>
              You agree not to use the Site or the Services to engage in prohibited activities, including violating any applicable laws, engaging in fraudulent transactions, or attempting to bypass restrictions using VPNs or other tools.
            </p>
          </div>
        )}
      </section>

      <section className="mb-6">
        <h2
          className="text-2xl font-semibold text-white cursor-pointer flex items-center justify-between"
          onClick={() => toggleSection('disputeResolution')}
        >
          Dispute Resolution & Arbitration
          {openSection === 'disputeResolution' ? <FaChevronUp /> : <FaChevronDown />}
        </h2>
        {openSection === 'disputeResolution' && (
          <div className="mt-4 text-white">
            <p>
              Any dispute arising from these Terms will be resolved through binding individual arbitration, in accordance with Panama Arbitration Law.
            </p>
          </div>
        )}
      </section>
    </div>
  );
};

export default Tac;
