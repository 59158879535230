import React, { useState, useEffect, useRef } from 'react';
import BetPanel from './BetPanel';
import ChartComponent from './ChartComponent';
import Rules from './Rules';
import OrderBook from './OrderBook';
import { useParams } from 'react-router-dom';
// Optional: If you want to add swipe functionality
// import { useSwipeable } from 'react-swipeable';

const EventPanel = () => {
  const { coin, id } = useParams();
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [activeTab, setActiveTab] = useState('Bet'); // Default to 'Bet'
  const [market, setMarket] = useState('');
  const [realTimePrice, setRealTimePrice] = useState(null); // State for real-time price
  const wsRef = useRef(null); // WebSocket reference

  const tabs = ['Bet', 'Charts']; // Define your tabs

  const fetchMarket = async (timeZone) => {
    try {
      const response = await fetch(`https://swaggy-api.debugged.com.my/api/market`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
        credentials: 'include',
      });
      const data = await response.json();
      setMarket(data);
      const newDate = new Date(`${data.end_time}Z`);
      const formattedDate = newDate.toLocaleDateString('en-US', {
        timeZone: timeZone,
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
      setDate(formattedDate);
      const formattedTime = newDate.toLocaleTimeString('en-US', {
        timeZone: timeZone,
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      setTime(formattedTime);
    } catch (error) {
      console.error('Error fetching market:', error);
    }
  };

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    fetchMarket(userTimeZone);

    // Set up WebSocket connection
    const symbol = coin.toLowerCase() + "usdt";
    wsRef.current = new WebSocket(`wss://stream.binance.com:9443/ws/${symbol}@trade`);
    console.log("start connection to websocket");

    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const price = parseFloat(data.p);
      setRealTimePrice(price); // Update real-time price
    };

    return () => {
      if (wsRef.current) {
        wsRef.current.close(); // Close WebSocket on component unmount
        console.log("close connection to websocket");
      }
    };
  }, []); // Empty dependency array to run once

  // Optional: Handle swipe gestures
  /*
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const currentIndex = tabs.indexOf(activeTab);
      if (currentIndex < tabs.length - 1) {
        setActiveTab(tabs[currentIndex + 1]);
      }
    },
    onSwipedRight: () => {
      const currentIndex = tabs.indexOf(activeTab);
      if (currentIndex > 0) {
        setActiveTab(tabs[currentIndex - 1]);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });
  */

  return (
    <div className="flex flex-col mt-25">
      {/* Mobile Buttons for switching between tabs (hidden on desktop) */}
      <div className="flex lg:hidden w-full">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`flex-1 px-4 py-2 font-semibold ${
              activeTab === tab
                ? 'bg-gray-600 text-white'
                : 'bg-gray-700 text-gray-200'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Pagination Dots */}
      {/* <div className="flex justify-center mt-2">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`h-2 w-2 rounded-full mx-1 transition-colors duration-300 ${
              activeTab === tab ? 'bg-gray-600' : 'bg-gray-300'
            }`}
          ></div>
        ))}
      </div> */}

      {/* Mobile layout */}
      <div className="flex flex-col lg:hidden">
        {/* Optional: Wrap with swipe handlers
            <div {...handlers}>
        */}
        {/* Conditional rendering based on activeTab */}
        {activeTab === 'Bet' && (
          <div className="w-full">
            <BetPanel
              coin={coin}
              id={market.id}
              min={market.min_size}
              max={market.max_size}
              odds={market.odds}
              targetDate={market.end_time}
              realTimePrice={realTimePrice}
            />
          </div>
        )}
        {activeTab === 'Charts' && (
          <div className="w-full">
            <ChartComponent coin={coin} realTimePrice={realTimePrice} />
          </div>
        )}
        {/* About (Rules) and OrderBook are below */}
        <div className="w-full">
          <Rules coin={coin} date={date} time={time} />
          <OrderBook id={market.id} />
        </div>
        {/* </div> */}
      </div>

      {/* Desktop layout */}
      <div className="hidden lg:flex w-full">
        {/* Main content area */}
        <div className="flex flex-col w-3/4 pr-4">
          {/* ChartComponent */}
          <div className="mb-4">
            <ChartComponent coin={coin} realTimePrice={realTimePrice} />
          </div>
          {/* Rules and OrderBook */}
          <div className="mb-4">
            <Rules coin={coin} date={date} time={time} />
            <OrderBook id={market.id} />
          </div>
        </div>
        {/* BetPanel area */}
        <div className="w-1/4">
          <div className="sticky top-20">
            <BetPanel
              coin={coin}
              id={market.id}
              min={market.min_size}
              max={market.max_size}
              odds={market.odds}
              targetDate={market.end_time}
              realTimePrice={realTimePrice}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPanel;
