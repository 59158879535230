import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import confettiImage from '../assets/images/confetti.png';
import failPaymentImage from '../assets/images/payment-error.png';
import pendingPaymentImage from '../assets/images/pending-payment.png';

const DepositModal = ({ onClose, isPaymentProcessed, paymentStatus }) => {
  // State variables
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [gateway, setGateway] = useState("");
  const [error, setError] = useState("");
  const [coinList, setCoinList] = useState([]);
  const [minAmount, setMinAmount] = useState(100);
  const [messages, setMessages] = useState({});

	useEffect(() => {
		const fetchList = async () => {
			try {
				const response = await fetch(`https://swaggy-api.debugged.com.my/api/payment/currencies`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${localStorage.getItem('sanctum_token')}`,
						'Content-Type': 'application/json'
					},
					credentials: 'include'
				});

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				
				setCoinList(data.data);
			} catch (error) {
				console.error("Error fetching rooms:", error);
			}
		};

		fetchList();
	}, []);

	useEffect(() => {
		let image = "";
		let title = "";
		let message = "";

		switch (paymentStatus) {
			case 'success':
				image = confettiImage;
				title = "Your Deposit Was Successful";
				message = "Thank you for your payment. Your deposit has been credited to your account.";

				break;
			case 'pending':
				image = pendingPaymentImage;
				title = "Processing Your Payment";
				message = "We’ve received your payment details. Confirmation may take a few minutes. Please check back shortly.";

				break;
		
			default:
				image = failPaymentImage;
				title = "Payment Unsuccessful";
				message = "Your deposit could not be completed. Please check your payment details or try a different method.";

				break;
		}

		setMessages({
			image, title, message
		});
		
	}, [setMessages]);

	useEffect(() => {
		const selectedCurrency = coinList.find(item => item.value == currency);
		console.log(selectedCurrency)
		if( selectedCurrency ) setGateway(selectedCurrency.gateway);
	}, [currency, setGateway]);

	const handleSubmit = async (event) => {
		event.preventDefault();

		if( amount <= 0 )
			setError("Amount is required");

		setIsLoading(true);

		try {
			const token = localStorage.getItem("sanctum_token");

			const response = await fetch("https://swaggy-api.debugged.com.my/api/payment/create", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					amount: amount,
					currency: currency,
					gateway: gateway,
				}),
			});

			const contentType = response.headers.get("content-type");

			if (contentType && contentType.includes("application/json")) {
				const data = await response.json();

				if (response.ok) {
					window.location = data.redirect_url
				}
			} else {
				const text = await response.text();
				console.error("Non-JSON response:", text);
				setError(`Unexpected response format: ${text}`);
			}

		} catch (error) {
			console.error('Fetch Error:', error);
			setError(error.message || "An error occurred, please try again later.");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div
			id="deposit-modal"
			tabIndex="-1"
			className="bg-black/50 fixed inset-0 overflow-y-auto flex items-start justify-center z-50 min-h-screen"
		>

			<div className="relative p-4 sm:p-6 w-full max-w-md bg-white rounded-lg shadow-md mt-8 mb-8">
				<button
					type="button"
					className="absolute top-2 right-2 sm:top-3 sm:right-3 text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-full p-1 sm:p-1.5"
					onClick={onClose}
				>
					<XMarkIcon className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
					<span className="sr-only">Close popup</span>
				</button>
				{ !isPaymentProcessed
					? <>
						<div className="p-6">
							<div className="text-center mb-2">
								<h2 className="text-xl sm:text-2xl font-semibold text-slate-900">
									Deposit
								</h2>
								<p className="text-sm sm:text-base text-slate-600 mt-2">
									Top Up Your Account to Join the Next Pool
								</p>
							</div>
						</div>

						<form className="" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="currency" className="text-sm font-semibold text-slate-900">
									Currency
								</label>
								<select 
									className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base text-slate-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mt-1"
									name="currency"
									value={currency}
									id="currency"
									onChange={(e) => setCurrency(e.target.value)}
									required
								>
									<option value="">Select Currency</option>
									{coinList.map((item, index) => (
										<option key={index} value={item.value}>
											{item.name}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="amount" className="text-sm font-semibold text-slate-900">
									Enter Amount
								</label>
								<input
									name="amount"
									type="number"
									min={minAmount}
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									required
									className="block w-full rounded-lg border border-gray-300 px-4 py-2 text-base text-slate-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-black mt-1"
									placeholder="0.00"
								/>
								{error && <p className="text-red-500">{error}</p>}
							</div>
							<button
								type="submit"
								className={`w-full py-3 rounded-lg bg-black text-white font-medium text-base sm:text-sm focus:ring-2 focus:ring-black mt-4 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
									}`}
								disabled={isLoading}
							>
								{isLoading ? "Loading..." : "Proceed"}
							</button>
						</form>
					</> 
					: <>
						<div className="p-6">
							<div className="flex items-center flex-col text-center mb-2">
								<img src={messages.image} alt="" className="w-24" />
								<h2 className="text-xl sm:text-2xl font-semibold text-slate-900 mt-6">
									{ messages.title }
								</h2>
								<p className="text-sm sm:text-base text-slate-600 mt-2">
									{ messages.message }
								</p>
							</div>
						</div>
					</>
				}
			</div>
		</div>
	);
};

export default DepositModal;