// App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Events from "./components/Events";
import UsersList from "./components/Admin/UsersList";
import ResetPassword from "./components/ResetPassword";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import Login from './components/Admin/Login';
import ProtectedRoute from './components/Admin/ProtectedRoute';
import LastBets from './components/Activity';
import CoinList from './components/CoinList';
import EventPanel from './components/EventPanel';
import Privacy from './components/Privacy';
import Tac from './components/Tac';
import About from './components/About';
import Settings from './components/User/Settings';
import Dashboard from './components/User/Dashboard';
import AssetRecords from './components/User/AssetRecords';
import OTPScreen from './components/User/OTPScreen';
import ConnectWallet from './components/User/ConnectWallet';
import LoginForm from './components/LoginForm';
import { CoinsProvider } from './contexts/CoinsContext';
import Activity from './components/RecentActivity';
import Test from './components/Test';
import PaymentStatus from "./components/PaymentStatus";
// Define apiHost and apiVersion (replace with your actual API host and version)
const apiHost = "https://swaggy-api.debugged.com.my";
const apiVersion = "/api";

function App() {
    return (
        <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
            <AuthProvider>
                <CoinsProvider>
                    <Router>
                        <ErrorBoundary>
                            <NavBar apiHost={apiHost} apiVersion={apiVersion} />
                            <Routes>
                                <Route path="/" element={<MainPage />} />
                                <Route path="/admin/login" element={<Login />} />
                                <Route path="/admin/users" element={<ProtectedRoute><UsersList /></ProtectedRoute>} />
                                {/* Routes for Password Recovery and Reset */}
                                <Route
                                  path="/forgot-password"
                                  element={<ResetPassword apiHost={apiHost} apiVersion={apiVersion} />}
                                />
                                {/* Main page */}
                                <Route path="/event/:coin/:id" element={<EventPanel />} />
                                <Route path="/privacy" element={<Privacy />} />
                                <Route path="/activity" element={<Activity />} />
                                <Route path="/tac" element={<Tac />} />
                                <Route path="/about" element={<About apiHost={apiHost} apiVersion={apiVersion} />} />
                                {/* User components */}
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/user/settings" element={<Settings />} />
                                <Route path="/user/assets/records" element={<AssetRecords />} />
                                <Route path="/user/connect_wallet" element={<ConnectWallet />} />
                                <Route path="/otp" element={<OTPScreen />} />
                                <Route path="/test" element={<Test />} />
                                <Route path="/payment/status/:gateway" element={<PaymentStatus />} />
                                {/* LoginForm Route */}
                                <Route
                                  path="/login"
                                  element={<LoginForm apiHost={apiHost} apiVersion={apiVersion} />}
                                />
                            </Routes>
                            <Footer />
                        </ErrorBoundary>
                    </Router>
                </CoinsProvider>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}

const MainPage = () => (
  <>
    <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
      <Events />
      <CoinList />
      <LastBets />
    </div>
  </>
);

export default App;