// src/contexts/CoinsContext.js

import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';

export const CoinsContext = createContext();

export const CoinsProvider = ({ children }) => {
  const [coinsData, setCoinsData] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [subscribedSymbols, setSubscribedSymbols] = useState([]);
  const ws = useRef(null);

  const ONE_DAY_MS = 24 * 60 * 60 * 1000;
  const MAX_PRICE_TREND_POINTS = 1440; // 24 hours of 1-minute data
  const INTERVAL = '1m';

  const fetchHistoricalData = async (symbol) => {
    try {
      const endTime = Date.now();
      const startTime = endTime - ONE_DAY_MS;
      const url = `https://api.binance.com/api/v3/klines?symbol=${symbol.toUpperCase()}&interval=${INTERVAL}&startTime=${startTime}&endTime=${endTime}`;
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching historical data for ${symbol}:`, error);
      return [];
    }
  };

  const fetchCoinsData = async () => {
    try {
      const coinsResponse = await fetch('https://swaggy-api.debugged.com.my/api/list', {
        method: 'GET',
        credentials: 'include',
      });
      const initialCoins = await coinsResponse.json();

      const updatedCoins = await Promise.all(
        initialCoins.map(async (coin) => {
          const historicalData = await fetchHistoricalData(coin.symbol);
          const priceTrend = historicalData.map((item) => parseFloat(item[4]));
          const currentPrice = priceTrend[priceTrend.length - 1] || null;
          const price24hAgo = priceTrend[0] || null;

          // Calculate percentage change over 24 hours
          let percentageChange = null;
          if (price24hAgo && currentPrice) {
            percentageChange = ((currentPrice - price24hAgo) / price24hAgo) * 100;
          }

          return { ...coin, priceTrend, currentPrice, percentageChange };
        })
      );

      setCoinsData(updatedCoins);
      setIsDataFetched(true);
    } catch (error) {
      console.error('Error fetching coin data:', error);
    }
  };

  const subscribeToKlines = useCallback(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN && coinsData.length > 0) {
      const params = coinsData.map((coin) => `${coin.symbol.toLowerCase()}@kline_1m`);
      const newSymbols = params.filter((symbol) => !subscribedSymbols.includes(symbol));

      if (newSymbols.length > 0) {
        const subscriptionMessage = {
          method: 'SUBSCRIBE',
          params: newSymbols,
          id: 1,
        };
        ws.current.send(JSON.stringify(subscriptionMessage));
        setSubscribedSymbols((prev) => [...prev, ...newSymbols]);
      }
    }
  }, [coinsData, subscribedSymbols]);

  const unsubscribeFromKlines = useCallback(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN && subscribedSymbols.length > 0) {
      const unsubscribeMessage = {
        method: 'UNSUBSCRIBE',
        params: subscribedSymbols,
        id: 2,
      };
      ws.current.send(JSON.stringify(unsubscribeMessage));
      setSubscribedSymbols([]);
    }
  }, [subscribedSymbols]);

  useEffect(() => {
    fetchCoinsData();
  }, []);

  useEffect(() => {
    if (isDataFetched) {
      ws.current = new WebSocket('wss://stream.binance.com:9443/ws');

      ws.current.onopen = subscribeToKlines;

      ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.e === 'kline' && message.k.x) {
          const { s: symbol, k: kline } = message;
          const currentPrice = parseFloat(kline.c);

          setCoinsData((prevCoins) =>
            prevCoins.map((coin) => {
              if (coin.symbol.toUpperCase() === symbol.toUpperCase()) {
                const updatedPriceTrend = [...(coin.priceTrend || []), currentPrice];
                const limitedPriceTrend = updatedPriceTrend.slice(-MAX_PRICE_TREND_POINTS);

                // Recalculate percentage change
                const price24hAgo = limitedPriceTrend[0];
                let percentageChange = null;
                if (price24hAgo && currentPrice) {
                  percentageChange = ((currentPrice - price24hAgo) / price24hAgo) * 100;
                }

                return {
                  ...coin,
                  currentPrice,
                  priceTrend: limitedPriceTrend,
                  percentageChange,
                };
              }
              return coin;
            })
          );
        }
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.current.onclose = () => {
        unsubscribeFromKlines();
        console.log('WebSocket closed');
      };

      return () => {
        unsubscribeFromKlines();
        if (ws.current) {
          ws.current.close();
        }
      };
    }
  }, [isDataFetched, subscribeToKlines, unsubscribeFromKlines]);

  useEffect(() => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      subscribeToKlines();
    }
  }, [coinsData, subscribeToKlines]);

  return (
    <CoinsContext.Provider value={{ coinsData, isDataFetched }}>
      {children}
    </CoinsContext.Provider>
  );
};
