import React from 'react';

function BetHistory({ bets, historyLoading }) {
  const profitLoss = (amount, award) => {
    return parseFloat(award - Math.abs(parseFloat(amount)).toFixed(2));
  }
  
  return (
    <div className="mt-10 bg-gray-800 text-white rounded">
      <h3 className="text-xl font-bold mb-4">Bet History</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto text-left">
          <thead>
            <tr>
              <th className="px-3 py-2 border-b border-gray-600">Amount</th>
              <th className="px-3 py-2 border-b border-gray-600">Coin</th>
              <th className="px-3 py-2 border-b border-gray-600">Time</th>
              <th className="px-3 py-2 border-b border-gray-600">Profit / Loss</th>
            </tr>
          </thead>
          <tbody>
            { historyLoading 
              ? <tr>
                  <td colSpan="4" className='text-center'>Loading...</td>
                </tr>
              : <></> 
            }
            {bets.map((bet, index) => (
              <tr key={index} className="border-t border-gray-700">
                <td className="px-3 py-2 border-b border-gray-600">${Math.abs(parseFloat(bet.amount)).toFixed(2)}</td>
                <td className="px-3 py-2 border-b border-gray-600">{bet.order?.pool?.name}</td>
                <td className="px-3 py-2 border-b border-gray-600">{new Date(bet.created_at).toLocaleString()}</td>
                <td className={`px-3 py-2 border-b border-gray-600 ${profitLoss(bet.amount, bet.order?.award) > 0 ? 'text-green-500' : 'text-red-500'}`}>
                  {profitLoss(bet.amount, bet.order?.award) > 0 ? `+${profitLoss(bet.amount, bet.order?.award).toFixed(2)}` : `${profitLoss(bet.amount, bet.order?.award).toFixed(2)}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BetHistory;