import { useEffect } from "react";

function RecaptchaV3({ action, onVerify }) {
  useEffect(() => {
    const executeRecaptcha = async () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute("6Lf5QVcqAAAAAPuo_0gGC9BC-Pz-UrGDnJjFU_8f", { action })
            .then((newToken) => {
              onVerify(newToken);
            });
        });
      }
    };
    executeRecaptcha();

    const interval = setInterval(() => {
      executeRecaptcha();
    }, 60000); // every minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [action, onVerify]);

  return null; // This component doesn't render anything.
}

export default RecaptchaV3;
