import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import chevron icons

const Rules = ({ coin, date, time }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mt-6 p-4 rounded-lg text-white">
      {/* Title */}
      <h2 className="text-xl font-bold mb-2">About</h2>

      {/* Summary Text */}
      <p className="mb-2">
        Users can predict the price of {coin.toUpperCase()}USDT. The final price will be determined at <strong> {time} on {date}</strong>.
      </p>

      {/* Show more button with dropdown icon */}
      {!isOpen && (
        <div
          className="flex items-center cursor-pointer"
          onClick={toggleDropdown}
        >
          <span>Show more</span>
          <FaChevronDown className="ml-2" />
        </div>
      )}

      {/* Full Text - Only Visible When Dropdown is Open */}
      {isOpen && (
        <>
          <div className="mt-2">
            <p className="mb-2">
              The resolution source for this market is Binance, specifically the {coin.toUpperCase()}USDT "Close" prices available at <a href={`https://www.binance.com/en/trade/${coin.toUpperCase()}_USDT`} className="underline text-blue-500">https://www.binance.com/en/trade/{coin.toUpperCase()}_USDT</a> with “1m” and “Candles” selected on the top bar.
            </p>
            <p>
              Please note that this market is based on the price according to Binance {coin.toUpperCase()}USDT and not according to other sources or spot markets.
            </p>
          </div>

          {/* Show less button moved to the bottom */}
          <div
            className="flex items-center cursor-pointer mt-4"
            onClick={toggleDropdown}
          >
            <span>Show less</span>
            <FaChevronUp className="ml-2" />
          </div>
        </>
      )}
    </div>
  );
};

export default Rules;
