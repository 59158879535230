import React from 'react';

function Pagination({ totalPages, currentPage, onPageChange }) {
  const pageNumbers = [];

  // Set up the logic to only show up to 5 page numbers with "..." as needed
  const maxVisiblePages = 2;

  // Calculate start and end page numbers
  let startPage = Math.max(1, currentPage - 2);
  let endPage = Math.min(totalPages, currentPage + 2);

  // Adjust the start or end if there are less than maxVisiblePages pages to show
  if (endPage - startPage + 1 < maxVisiblePages) {
    if (startPage === 1) {
      endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    } else if (endPage === totalPages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
  }

  // Create page buttons to display
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  // Handle page change
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className="flex justify-end items-center mt-4">
      <div className="rounded-lg border space-x-2">
				<button
					onClick={() => goToPage(currentPage - 1)}
					disabled={currentPage === 1}
					className="px-4 py-2 text-sm disabled:opacity-25"
				>
					Previous
				</button>
				<span className='m-0'>|</span>
				{/* Show "1" and "..." at the beginning */}
				{startPage > 1 && (
					<>
						<button 
							onClick={() => goToPage(1)}
							className={`px-4 py-2 text-sm ${currentPage === 1 ? 'border' : ''}`}
						>
							1
						</button>
						{startPage > 2 && <span className="px-4 py-2 text-sm">...</span>}
					</>
				)}

				{/* Render the main set of page numbers */}
				{pageNumbers.map((number) => (
					<button
						key={number}
						onClick={() => goToPage(number)}
						className={`px-4 py-2 text-sm ${number === currentPage ? 'border' : ''}`}
					>
						{number}
					</button>
				))}
				{/* Show "..." and last page at the end */}
				{endPage < totalPages && (
					<>
						{endPage < totalPages - 1 && <span className="px-4 py-2 text-sm">...</span>}
						<button 
							onClick={() => goToPage(totalPages)}
							className={`px-4 py-2 text-sm ${currentPage === totalPages ? 'border' : ''} rounded-lg`}
						>
							{totalPages}
						</button>
					</>
				)}
				<span className='m-0'>|</span>
				<button
					onClick={() => goToPage(currentPage + 1)}
					disabled={currentPage === totalPages}
					className="px-4 py-2 text-sm disabled:opacity-25"
				>
					Next
				</button>
			</div>
    </div>
  );
}

export default Pagination;