// CoinList.js
import React, { useState, useEffect, useRef } from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import Spinner from './Spinner'; // Ensure the path is correct

// Utility function to format prices with commas
const formatPrice = (price) => {
  if (price === null || price === undefined) return null; // Return null instead of 'N/A'
  return `$${parseFloat(price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

// Mapping of symbols to unique IDs
const SYMBOL_ID_MAPPING = {
  BTCUSDT: 1,
  ETHUSDT: 2,
  BNBUSDT: 3,
  XRPUSDT: 4,
  DOGEUSDT: 5,
  SOLUSDT: 6,
  // Add more mappings as needed
};

// List of symbols to display
const SYMBOLS = Object.keys(SYMBOL_ID_MAPPING); // ['BTCUSDT', 'ETHUSDT', ...]

const CoinList = () => {
  const [coinsData, setCoinsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [error, setError] = useState(null); // Error state
  const [symbolIdMapping, setSymbolIdMapping] = useState({});
  const [symbols, setSymbols] = useState([]);
  const navigate = useNavigate();
  const ws = useRef(null); // WebSocket reference

  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        const response = await fetch(
          "https://swaggy-api.debugged.com.my/api/list"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const symbolsMapping = data.reduce((acc, symbol) => {
          acc[symbol.symbol] = symbol.id;
          return acc;
        }, {});
        setSymbolIdMapping(symbolsMapping);
        setSymbols(Object.keys(symbolsMapping));
      } catch (error) {
        console.error("Error fetching symbols:", error);
      }
    };

    fetchSymbols();
  }, []);

  // Initialize WebSocket connection for live price updates
  useEffect(() => {
    if (symbols.length === 0) return;

    // Construct the combined stream string
    const combinedStream = symbols.map((symbol) => symbol.toLowerCase() + '@trade').join('/');

    // Binance Combined Streams WebSocket URL
    const BINANCE_WS_URL = `wss://stream.binance.com:9443/stream?streams=${combinedStream}`;

    ws.current = new WebSocket(BINANCE_WS_URL);

    ws.current.onopen = () => {
      console.log('WebSocket connection opened');
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // Ensure the message contains trade data
      if (message && message.data && message.stream) {
        const trade = message.data;
        const symbol = trade.s; // Symbol e.g., 'ETHUSDT'
        const price = parseFloat(trade.p); // Price as float

        setCoinsData((prevCoinsData) =>
          prevCoinsData.map((coin) =>
            coin.symbol === symbol
              ? {
                  ...coin,
                  currentPrice: price, // Update only currentPrice
                  // Do not modify priceTrend
                }
              : coin
          )
        );
      }
    };

    ws.current.onerror = (err) => {
      console.error('WebSocket error:', err);
      setError('WebSocket connection error. Please refresh the page.');
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    // Cleanup on unmount
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [symbols]); // Empty dependency array ensures this runs once on mount

  // Fetch initial data (current prices and historical priceTrend)
  const fetchCoinsData = async () => {
    try {
      // Fetch current prices for all symbols
      const pricesResponse = await fetch('https://api.binance.com/api/v3/ticker/price');
      const pricesData = await pricesResponse.json();

      // Filter pricesData for the symbols we are interested in
      const filteredPrices = pricesData.filter((price) => symbols.includes(price.symbol));

      // Fetch historical data for each symbol
      const historicalDataPromises = symbols.map(async (symbol) => {
        const response = await fetch(`https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=1h&limit=24`);
        const klines = await response.json();

        // Extract the closing prices for the sparkline
        const closingPrices = klines.map((kline) => parseFloat(kline[4])); // Close price is the 5th element

        return {
          symbol,
          id: symbolIdMapping[symbol],
          currentPrice: parseFloat(filteredPrices.find((price) => price.symbol === symbol)?.price) || null,
          priceTrend: closingPrices,
          initialPrice: closingPrices[0] || null, // For percentage change
        };
      });

      const historicalData = await Promise.all(historicalDataPromises);
      console.log('Historical Data:', historicalData); // Debug log

      setCoinsData(historicalData);
      setIsDataFetched(true);
      setError(null); // Reset error state on success
    } catch (error) {
      console.error('Error fetching coin data:', error);
      setError('Failed to load coin data. Please try again later.');
    }
  };

  useEffect(() => {
    fetchCoinsData();

    // Optionally, set up an interval to refresh historical data periodically
    // Not necessary since the chart should only update on refresh
    // If desired, uncomment the following lines:
    /*
    const interval = setInterval(fetchCoinsData, 60 * 60 * 1000); // Refresh every hour
    return () => clearInterval(interval); // Clean up on unmount
    */
  }, [symbols, symbolIdMapping]); // Empty dependency array ensures this runs once on mount

  // Filter coins based on search term
  const filteredCoins = coinsData.filter((coin) =>
    coin.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
    coin.symbol.slice(0, -4).toLowerCase().includes(searchTerm.toLowerCase()) // Allow searching by base symbol (e.g., BTC)
  );

  // Determine line color based on price trend
  const getLineColor = (trend) => {
    if (trend && trend.length > 0) {
      return trend[trend.length - 1] >= trend[0] ? '#00ff66' : '#e13131'; // Green or Red
    }
    return 'gray';
  };

  // Calculate percentage change
  const calculatePercentageChange = (currentPrice, initialPrice) => {
    if (initialPrice === null || initialPrice === 0) return null;
    const change = ((currentPrice - initialPrice) / initialPrice) * 100;
    return change.toFixed(2); // Two decimal places
  };

  // Handle click on a coin card
  const handleCoinClick = (symbol, id) => {
    const baseSymbol = symbol.slice(0, -4).toLowerCase(); // e.g., 'DOGE' from 'DOGEUSDT'
    navigate(`/event/${baseSymbol}/${id}`);
  };

  return (
    <div className="flex flex-col items-center justify-center mt-5 text-white">
      {/* Search Input */}
      <div className="w-full">
        <div className="relative flex items-center justify-center mb-4">
          <MagnifyingGlassIcon className="absolute left-3 h-5 w-5 text-gray-200" />
          <input
            type="text"
            placeholder="Search markets"
            className="pl-10 pr-3 py-2 h-10 bg-transparent placeholder-white rounded-md w-full border-2 border-gray-700 focus:border-blue-500 focus:outline-none mx-auto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Error Message */}
        {error && (
          <div className="text-center mt-6 text-red-500">
            <p>{error}</p>
          </div>
        )}

        {/* Coin Grid */}
        {isDataFetched ? (
          filteredCoins.length > 0 ? (
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
              {filteredCoins.map((coin) => {
                console.log('Rendering Coin:', coin); // Debug log
                const percentageChange = calculatePercentageChange(coin.currentPrice, coin.initialPrice);
                const isPositive = percentageChange !== null && parseFloat(percentageChange) >= 0;

                return (
                  <div
                    key={coin.symbol}
                    className="relative bg-gradient-to-l from-gray-800 to-gray-700 rounded-lg shadow-md hover:shadow-xl transition duration-300 ease-in-out p-4 cursor-pointer flex flex-col justify-between"
                    onClick={() => handleCoinClick(coin.symbol, coin.id)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handleCoinClick(coin.symbol, coin.id);
                    }}
                    aria-label={`View details for ${coin.symbol.slice(0, -4)}/USDT`}
                  >
                    {/* Top Row: Exchange Name, Coin Name and Mini Chart */}
                    <div className="flex justify-between items-start">
                      {/* Exchange Name and Coin Name */}
                      <div className="flex flex-col">
                        {/* Added Exchange Name */}
                        <p className="text-xs text-gray-400">BINANCE</p>
                        <h3 className="text-md font-semibold text-gray-200">
                          {coin.symbol.slice(0, -4)}/USDT
                        </h3>
                      </div>

                      {/* Mini Price Trend Chart */}
                      <div className="w-20 h-15 pl-3">
                        {coin.priceTrend == null || coin.priceTrend.length === 0 ? (
                          <Spinner />
                        ) : (
                          <Sparklines data={coin.priceTrend} width={80} height={30} margin={2}>
                            <SparklinesLine
                              color={getLineColor(coin.priceTrend)}
                              style={{ strokeWidth: 2, fill: 'none' }}
                            />
                          </Sparklines>
                        )}
                      </div>
                    </div>

                    {/* Current Price */}
                    <div className="mt-1">
                      <p className="text-sm font-bold">
                        {coin.currentPrice !== null && coin.currentPrice !== undefined ? (
                          formatPrice(coin.currentPrice)
                        ) : (
                          <Spinner />
                        )}
                      </p>
                    </div>

                    {/* Percentage Change */}
                    <div>
                      {percentageChange !== null ? (
                        <p className={`text-xs font-semibold ${isPositive ? 'text-green-400' : 'text-red-400'}`}>
                          {isPositive ? '+' : ''}
                          {percentageChange}%
                        </p>
                      ) : (
                        <Spinner />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <p>No coins found</p>
            </div>
          )
        ) : (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default CoinList;
