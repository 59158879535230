import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext'; 
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FaCog, FaWallet, FaArrowUp, FaArrowDown } from 'react-icons/fa'; 
import BetHistory from './BetHistory';
import DepositModal from '../DepositModal';
import Pagination from '../Pagination';

function Dashboard() {
  const { user, balance } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlPaymentStatus = searchParams.get('status');

  // Bet history state variables
  const [betHistory, setBetHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [currentHistoryPage, setCurrentHistoryPage] = useState(1);
  const [totalHistoryPage, setTotalHistoryPage] = useState(0);

  useEffect(() => {
    const fetchBetHistory = async () => {
      setHistoryLoading(true);

      try {
        const response = await fetch(`https://swaggy-api.debugged.com.my/api/transaction-history`, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('sanctum_token')}`,
              'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({
            type: "order",
            page: currentHistoryPage
          }),
        });

        if (!response.ok) {
          throw new Error(`Error fetching bet history: ${response.statusText}`);
        }

        const data = await response.json();
        setBetHistory(data.order.data);
        setTotalHistoryPage(data.order.last_page);
      } catch (error) {
        console.error("Error fetching bet history:", error);
        setBetHistory([]);
      } finally {
        setHistoryLoading(false);
      }
    };

    fetchBetHistory();
  }, [currentHistoryPage]);

  const handleSettingsClick = () => {
    navigate('/user/settings');
  };

  const handlePageChange = (page) => {
    setCurrentHistoryPage(page);
  };

  // Deposit state variables
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);

  const toggleDepositModal = () => {
    setShowDepositModal(!showDepositModal);
  };

  const handleDepositModal = () => {
    setIsPaymentProcessed(false);
    toggleDepositModal();
  };

  useEffect(() => {
    if (urlPaymentStatus) {
      setIsPaymentProcessed(true);
      setPaymentStatus(urlPaymentStatus);

      toggleDepositModal();

      searchParams.delete('status');
      searchParams.delete('gateway');
      setSearchParams(searchParams, { replace: true });
    }
  }, [urlPaymentStatus, setSearchParams, searchParams]);

  return (
    <div className="mt-20 p-4 text-white">
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-center p-6 rounded-lg shadow-lg bg-gradient-to-r from-purple-500 to-indigo-600">
        <div>
          <h2 className="text-3xl font-bold mb-2">Welcome back, {user ? user.name : 'Guest'}</h2>
          <p className="text-sm text-gray-200">Here's what's happening with your account today.</p>
        </div>
        <button
          className="text-white hover:text-gray-300 focus:outline-none mt-4 sm:mt-0"
          onClick={handleSettingsClick}
        >
          <FaCog size={24} />
        </button>
      </div>

      {/* Main Content */}
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Balance Card */}
        <div className="col-span-1 bg-gray-800 p-6 rounded-lg shadow-lg">
          <div className="flex items-center">
            <div className="p-4 bg-indigo-500 rounded-full">
              <FaWallet size={24} />
            </div>
            <div className="ml-4">
              <h3 className="text-lg font-semibold">Total Balance</h3>
              <p className="text-2xl font-bold">${balance !== null ? balance.toFixed(2) : 'Loading...'}</p>
            </div>
          </div>
          <div className="mt-6 flex space-x-2">
            <button 
              className="flex-1 px-4 py-2 font-bold bg-green-500 hover:bg-green-600 text-white rounded"
              onClick={handleDepositModal}
            >
              Deposit
            </button>
            <button className="flex-1 px-4 py-2 font-bold bg-red-500 hover:bg-red-600 text-white rounded">
              Withdraw
            </button>
          </div>
        </div>

        {/* Additional Cards */}
        <div className="col-span-1 lg:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Recent Activity */}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Recent Activity</h3>
            {/* Placeholder for recent activity */}
            <p className="text-gray-400">No recent activity.</p>
          </div>

          {/* Statistics */}
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Statistics</h3>
            {/* Placeholder for statistics */}
            <p className="text-gray-400">No statistics available.</p>
          </div>
        </div>
      </div>

      {/* Bet History */}
      <div className="mt-8 bg-gray-800 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold mb-4">Bet History</h3>
        <BetHistory 
          bets={betHistory} 
          historyLoading={historyLoading} 
        />
        {!historyLoading && totalHistoryPage > 1 && (
          <Pagination
            totalPages={totalHistoryPage}
            currentPage={currentHistoryPage}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      {/* Deposit Modal */}
      {showDepositModal && (
        <DepositModal 
          onClose={toggleDepositModal} 
          isPaymentProcessed={isPaymentProcessed}
          paymentStatus={paymentStatus}
        />
      )}
    </div>
  );
}

export default Dashboard;
